<template>
  <div class="echarts-page-web" v-loading="loading">
    <div class="home pc" v-cloak >
      <div id="printButton">
        <el-button type="primary" @click="print" class="print"
        >打印本页报告</el-button
        >
        <el-button
            type="primary"
            style="margin-left: 20px"
            @click="sendEmail"
            class="print noprint"
            v-if="rolecode"
        >发送邮箱</el-button>
      </div>

      <!--startprint-->
      <div style="text-align: center;font-size: 28px;font-weight: bold;margin-bottom: 50px">
        {{ this.$route.query.hospitalfullname }}AI心理健康测评报告
      </div>
      <div style="text-align: center;margin-left: 10px;margin-bottom: 150px">
        <div style="float: left;margin-left: 5px;margin-right:5px">
          <img :src="this.imgPath" alt="" width="120"/>
        </div>
        <div style="float: left;margin-left: 10px">
          <img
              v-if="this.$route.query.qrcodeurl"
              :src="this.$route.query.qrcodeurl"
              alt=""
              width="120"
          />
        </div>
      </div>
      <div style="margin-left: 80%; margin-bottom: 5px;font-size: 16px" >
        报告日期:{{ $route.query.time.slice(0, 16) }}
      </div>
      <div class="pc-box">
        <div class="item-wrap">
          <div class="item-wrap-title">
            <div class="line"></div>
            基础信息
          </div>
        </div>

        <div class="item-wrap-flex">
          <!--  基本信息表-->
          <div class="item-wrap-cell">
            <div class="label">姓名：</div>
            <div class="value">{{ reportData.name }}</div>
          </div>
          <div class="item-wrap-cell">
            <div class="label">报告ID编号：</div>
            <div class="value">{{ reportData.cusercode.slice(0,11) + reportData.ordercode.slice(0,5) }}</div>
          </div>
          <div class="item-wrap-cell">
            <div class="label">测评地点：</div>
            <div class="value">{{ reportData.address }}</div>
          </div>
          <div class="item-wrap-cell">
            <div class="label">您的指导医生：</div>
            <div class="value">{{ reportData.zdys }}</div>
          </div>
          <div class="item-wrap-cell">
            <div class="label">民族：</div>
            <div class="value">{{ reportData.mz }}</div>
          </div>
          <div class="item-wrap-cell">
            <div class="label">检测日期：</div>
            <div class="value">{{ $route.query.time.slice(0, 10) }}</div>
          </div>
          <!--          <div class="item-wrap-cell">-->
          <!--            <div class="label">是否独生子女：</div>-->
          <!--            <div class="value">{{ reportData.dszn }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell">-->
          <!--            <div class="label">居住条件：</div>-->
          <!--            <div class="value">{{ reportData.jztj }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell">-->
          <!--            <div class="label">居住方式：</div>-->
          <!--            <div class="value">{{ reportData.jzfs }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell">-->
          <!--            <div class="label">医疗付费方式：</div>-->
          <!--            <div class="value">{{ reportData.fffs }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell">-->
          <!--            <div class="label">文化程度：</div>-->
          <!--            <div class="value">{{ reportData.whcd }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell">-->
          <!--            <div class="label">有无宗教信仰：</div>-->
          <!--            <div class="value">{{ reportData.zjxy }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell">-->
          <!--            <div class="label">从事职业：</div>-->
          <!--            <div class="value">{{ reportData.gzzk }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell">-->
          <!--            <div class="label">目前工作状况：</div>-->
          <!--            <div class="value">{{ reportData.gzzt }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell">-->
          <!--            <div class="label">个人年收入：</div>-->
          <!--            <div class="value">{{ reportData.grnsr }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell">-->
          <!--            <div class="label">家庭年收入：</div>-->
          <!--            <div class="value">{{ reportData.jtnsr }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell bbn">-->
          <!--            <div class="label">婚姻状况：</div>-->
          <!--            <div class="value">{{ reportData.hyzk }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell bbn">-->
          <!--            <div class="label">你对现在婚姻状态满意度：</div>-->
          <!--            <div class="value">{{ reportData.hhmyzt }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell bbn">-->
          <!--            <div class="label">你对婚后婆媳关系状态满意度：</div>-->
          <!--            <div class="value">{{ reportData.hhpxzt }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell bbn">-->
          <!--            <div class="label">末次月经时间：</div>-->
          <!--            <div class="value">{{ reportData.mcyjsj }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell bbn">-->
          <!--            <div class="label">当前怀孕胎次：</div>-->
          <!--            <div class="value">{{ reportData.hyzs }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell bbn">-->
          <!--            <div class="label">躯体疾病：</div>-->
          <!--            <div class="value">{{ reportData.qtjb || "" }}</div>-->
          <!--          </div>-->
          <!--          <div class="item-wrap-cell bbn">-->
          <!--            <div class="label">不良妊娠：</div>-->
          <!--            <div class="value">{{ reportData.blrc }}</div>-->
          <!--          </div>-->
        </div>
        <div class="item-wrap">
          <div class="item-wrap-title">
            <div class="line"></div>
            报告详情
          </div>
        </div>
        <el-row>
          <el-col :span="12">
            <div id="phq9-chart" style="height: 400px"></div>
          </el-col>
          <el-col :span="12">
            <div id="qoute-chart" style="height: 400px"></div>
          </el-col>
        </el-row>
        <div class="item-wrap-flex" style="border: none">
          <div class="item-wrap-cell">
            <div class="label" style="width: 130px">抑郁筛查：{{phq9total}}</div>
          </div>
        </div>
        <div class="item-wrap-flex">
          <div class="item-wrap-cell" v-bind:key="index" v-for="(item, index) in quotaLabel" style="width: 25%">
            <div class="label">{{ item.quotaname }}：{{ item.quotascore }}</div>
          </div>
        </div>

        <div class="item-wrap">
          <div class="item-wrap-title">
            <div class="line"></div>
            测评结果
          </div>
        </div>
        <div class="item-wrap-content pc-cell">{{ detail.assessgrade }}</div>
        <div class="item-wrap">
          <div class="item-wrap-title" style="margin-bottom: -16px">
            <div class="line"></div>
            报告总结
          </div>
        </div>
        <div class="item-wrap-content pc-result-cell">
          <div v-html="detail.assessdetail"></div>
        </div>
        <div style="margin-bottom: 30px">
          <div style="text-align: center;float: right;margin-right: 20px;font-size: 18px;font-weight: bold">
            <span>孕妈妈的身心健康 决定了宝宝的身体健康</span>
          </div>
        </div>
      </div>
      <!--endprint-->
      <div style="text-align: left;color: #8c939d" >
        <p>&ensp;&ensp;注：本报告仅反映测评当时状态，请结合临床诊断建议。</p>
      </div>
    </div>

  </div>
</template>

<script>
import * as echarts from "echarts";
import ecStat from "echarts-stat";
import imgPath from "../../../assets/gzh.png";

const chartMap = [
  {
    typecode: '002',
    quotename: '妊娠压力'
  },
  {
    typecode: '003',
    quotename: '焦虑'
  },
  {
    typecode: '004',
    quotename: '抑郁'
  },
  {
    typecode: '006',
    quotename: '产后情绪'
  },
  {
    typecode: '009',
    quotename: '焦虑'
  },
  {
    typecode: '010',
    quotename: '躯体症状'
  },
  {
    typecode: '011',
    quotename: '认知功能'
  },
  {
    typecode: '012',
    quotename: '应对方式'
  },
  {
    typecode: '013',
    quotename: '社会支持'
  },
  {
    typecode: '014',
    quotename: '轻躁狂症状'
  },
  {
    typecode: '016',
    quotename: '睡眠质量'
  },
  {
    typecode: '017',
    quotename: '生活质量'
  },
  {
    typecode: '018',
    quotename: '失眠严重程度'
  },
]

export default {
  data() {
    return {
      myChart: "",
      detail: "",
      reportcontext: [],
      active: 0,
      reportList: [],
      excelList: [],
      reportData: {},
      loading: true,
      rolecode: false,
      imgPath,
      quotaLabel: [],
      phq9total: 0,
    };
  },
  mounted() {
    this.loginData = JSON.parse(localStorage.getItem("logindata"));
    let showAll = this.loginData.urls.find((e) => e.url === "report-email"); //是否没有报告全部权限
    if (showAll) this.rolecode = true; //如果code为false则没有报告全部权限
    this.reportsRequset();
  },
  methods: {
    async reportsRequset() {
      let params = {
        reportid: this.$route.query.reportsid,
      };
      const res = await this.$https.reportsService.getReportDetail(params);
      if (res) {
        const detail = res;
        this.detail = res;
        this.reportList = detail ? detail.reportcontext.slice(1) : []; //列表展示字段
        this.reportData = detail ? detail.reportcontext[0] : {}; //基础信息展示字段
        let reportcontext = detail ? detail.reportcontext.slice(1) : []; //图表展示字段
        this.reportcontext = reportcontext;
        console.log("报告原本数量："+this.reportList.length)
        for (let i = 0; i < this.reportList.length; i++) {
          if(i<Math.floor(this.reportList.length*0.6)){
            this.excelList.push(this.reportList[i]);
          }
        }
        // this.reportList.forEach((item)=>{
        //     if(item.typecode!='010'&&item.typecode!='014'&&item.typecode!='011'){
        //       this.excelList.push(item);
        //     }
        // })
        setTimeout(() => {
          const data1 = this.initEchartsFun(reportcontext);
          const data2 = this.lineEchartFun(reportcontext);
          const data3 = this.compareEChartsFun(reportcontext);
          const data4 = this.scatterEChart(reportcontext);
          this.initPHQ9Chart(detail.reportcontext);
          this.initQuoteChart(reportcontext)
          Promise.all([data1, data2, data3, data4])
              .then(() => {
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
                // vant.Dialog.alert({
                // 	title: '提示',
                // 	message: `图表显示不全,请联系客服`,
                // })
              });
        }, 1000);
      }
    },
    // change(e) {
    //   this.active = e;
    // },
    initPHQ9Chart(reportcontext){
      var option = {
        tooltip: {
          formatter: '{a} <br/>{b} : {c}%'
        },
        series: [
          {
            name: 'Pressure',
            type: 'gauge',
            min: 0,
            max: 27,
            splitNumber: 27,
            progress: {
              show: false
            },
            startAngle: 225,
            endAngle: -45,
            axisLine: {
              lineStyle: {
                width: 40,
                color: [
                  [0.148, '#4CEA34'],
                  [0.296, '#4CC234'],
                  [0.444, '#fac858'],
                  [0.592, '#ED6424'],
                  [1, '#FF2934']
                ]
              }
            },
            axisLabel: {
              color: '#464646',
              fontSize: 12,
              distance: -70,
              rotate: 'tangential',
              formatter: function (value) {
                if (value === 2) {
                  return '无';
                } else if (parseInt(value) === 6) {
                  return '轻度';
                } else if (parseInt(value) === 10) {
                  return '中度';
                } else if (parseInt(value) === 14) {
                  return '中重度';
                } else if (parseInt(value) === 22) {
                  return '重度';
                } else if (value === 27) {
                  return '';
                }
                return '';
              }
            },
            pointer: {
              itemStyle: {
                color: '#333'
              },
              width: 6,
              length: '70%',
              offsetCenter: [0, '8%']
            },
            detail: {
              valueAnimation: false,
              formatter: '{value}',
              fontSize: 15,
            },
            data: []
          }
        ]
      };

      for (let i = 0; i < reportcontext.length; i ++) {
        let typecode = reportcontext[i].typecode;
        let quotascore = 0;
        if (typecode == '008') {
          quotascore = reportcontext[i].quotascore;
          this.phq9total = parseInt(quotascore);
          option.series[0].data.push({
            value: quotascore,
            name: '抑郁筛查',
            detail: {
              formatter: function (value) {
                // return parseInt(value / 27 * 100) + '%';
                return value;
              }
            }
          });
        }
      }
      if (option.series[0].data.length == 0) {
        option.series[0].data.push({
          value: 0,
          name: '抑郁筛查',
          detail: {
            formatter: function (value) {
              // return parseInt(value / 27 * 100) + '%';
              return value;
            }
          }
        });
      }
      var chartDom = document.getElementById('phq9-chart');
      var myChart = echarts.init(chartDom);

      option && myChart.setOption(option);
    },
    initQuoteChart(reportcontext) {
      var color = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
      var option = {
        xAxis: {
          axisLabel: {
            interval: 0,
            rotate: 45,
          },
          axisLine: {
            onZero : false
          },
          type: 'category',
          data: []
        },
        yAxis: {
          max: 100,
          min: 0,
          type: 'value',
          axisLabel: {
            formatter: '{value} %'
          }
        },
        series: [
          {
            label: {
              show: false,
              position: 'top',
              color: '#333'
            },
            barMaxWidth: 30,
            barMinHeight: 10,
            data: [],
            type: 'bar'
          }
        ]
      };
      let total = []; // 汇总
      let index = 0;
      for (let i = 0; i < reportcontext.length; i ++) {
        index = i;
        // console.log(reportcontext[i].typename + "6666666")
        let typecode = reportcontext[i].typecode;
        // let quotaList = reportcontext[i].quotaList;
        let quotaname = '';
        let quotascore = reportcontext[i].quotascore;
        let quotatotalpoints = reportcontext[i].quotatotalpoints;
        // if (quotaList) {
        //   for (let j = 0; j < quotaList.length; j ++) {
        //     if (quotaList[j].quotascore > 0) {
        //       quotaname.push(quotaList[j].quotaname)
        //       quotascore.push(parseFloat(quotaList[j].quotascore))
        //     }
        //   }
        // } else {
        //   if (reportcontext[i].quotascore > 0) {
        //     quotaname.push(reportcontext[i].quotaname)
        //     quotascore.push(parseFloat(reportcontext[i].quotascore))
        //   }
        // }
        if (typecode == '002' || typecode == '003' || typecode == '004' || typecode == '006' || typecode == '009'
            || typecode == '010' || typecode == '011' || typecode == '012' || typecode == '013' || typecode == '014' || typecode == '018') {
          for (let k = 0; k < chartMap.length; k ++) {
            if (chartMap[k].typecode == typecode) {
              quotaname = chartMap[k].quotename;
            }
          }
          total.push({
            typecode: typecode,
            quotaname: quotaname,
            quotascore: parseFloat(quotascore)
          });
          option.xAxis.data.push(quotaname)
          option.series[0].data.push({
            value: parseInt(parseFloat(quotascore) / quotatotalpoints * 100),
            itemStyle: {
              color: [color[index % color.length]]
            }
          })
        }
      }
      var chartDom = document.getElementById('qoute-chart');
      var myChart = echarts.init(chartDom);
      option && myChart.setOption(option);
      return total;
    },
    sendEmail() {
      const email = this.$route.query.email;
      if (!email) {
        this.$message({
          showClose: true,
          message: "此报告没有设置邮件",
          type: "warning",
        });
      } else {
        let params = "";
        params = {
          reportid: this.$route.query.reportsid,
          openid: this.$route.query.openid,
        };
        this.$https.reportsService.sendReportEmail(params).then(() => {
          this.$message({
            showClose: true,
            message: "发送成功",
            type: "success",
          });
        });
      }
    },
    format(percentage) {
      return percentage === 100 ? "" : ``;
    },
    print() {
      document.getElementById("printButton").hidden=true;
      window.print();
      document.getElementById("printButton").hidden=false;
    },
    // 获取最大值
    getMaxV(distributionInfo) {
      let max = 0;
      for (let item of distributionInfo) {
        if (max < Number(item)) max = Number(item);
      }
      return max;
    },

    // 获取最小值
    getMinV(distributionInfo) {
      let min = 1000000;
      for (let item of distributionInfo) {
        if (min > Number(item)) min = Number(item);
      }
      return min;
    },
    returnText(type, row, col, text) {
      return {
        type: "group",
        ...row,
        ...col,
        children: [
          {
            type: "text",
            style: {
              fill: "#333",
              text: [text],
              font: "12px Microsoft YaHei",
            },
          },
        ],
      };
    },
    // 归一化处理
    normalization(distribution, max, min) {
      let normalizationRatio = (distribution - min) / (max - min);
      return normalizationRatio;
    },
    scatterEChart(reportcontext) {
      try {
        let list = [];
        let item = reportcontext.find((e) => e.typecode == "005").quotaList;
        item.map((e, i) => {
          list.push([i, e.quotascoreaverage]);
        });
        let labelList = item.map((e) => e.quotaname);
        this.scatterEChart = echarts.init(
            document.getElementById("scatterEChart")
        );
        this.scatterEChart.setOption({
          tooltip: {},
          xAxis: {
            data: labelList,
            axisLabel: {
              // color: "rgba(95,112,132,1)",
              color: "black",
              fontSize: "8",
              interval: 0,
              formatter: function (val) {
                var strs = val.split(""); //字符串数组
                var str = "";
                for (var i = 0, s; (s = strs[i++]); ) {
                  //遍历字符串数组
                  str += s;
                  if (!(i % 2)) str += "\n"; //按需要求余
                }
                return str;
              },
            },
          },
          yAxis: {},
          grid: {
            top: "12%",
            left: "10%",
            right: "12%",
            bottom: "12%",
          },
          series: [
            {
              symbolSize: 10,
              data: list,
              type: "scatter",
            },
          ],
        });
      } catch {
        console.log("数据错误");
      }
    },
    compareEChartsFun(reportcontext) {
      try {
        const item = reportcontext.find((e) => e.typecode == "015");
        if (!item) return;
        const EX =
            item.quotaList &&
            item.quotaList.find((e) => e.quotaname == "E维度（内向-外向）");
        const EY =
            item.quotaList &&
            item.quotaList.find((e) => e.quotaname == "N维度（神经质）");

        let xNum = (EX.quotascore / EX.quotatotalpoints) * 100;
        let yNum = (EY.quotascore / EY.quotatotalpoints) * 100;
        // let colCell = ECOL.
        this.compareEcharts = echarts.init(
            document.getElementById("compareEcharts")
        );
        echarts.registerTransform(ecStat.transform.clustering);
        let data = [[xNum, yNum]];
        let CLUSTER_COUNT = 2;
        let DIENSIION_CLUSTER_INDEX = 2;
        this.compareEcharts.setOption({
          dataset: [
            {
              source: data,
            },
            {
              transform: {
                type: "ecStat:clustering",
                // print: true,
                config: {
                  clusterCount: CLUSTER_COUNT,
                  outputType: "single",
                  outputClusterIndexDimension: DIENSIION_CLUSTER_INDEX,
                },
              },
            },
          ],
          grid: {
            top: "14%",
            left: "18%",
            right: "22%",
            bottom: "14%",
          },
          xAxis: {
            max: 100,
            min: 0,
            splitNumber: 2,
          },
          yAxis: {
            max: 100,
            min: 0,
          },
          graphic: [
            this.returnText(
                "group",
                { left: "23%" },
                { bottom: "16%" },
                "内向 稳定"
            ),
            this.returnText(
                "group",
                { right: "23%" },
                { bottom: "16%" },
                "外向 稳定"
            ),
            this.returnText(
                "group",
                { left: "23%" },
                { top: "15%" },
                "内向 不稳定"
            ),
            this.returnText(
                "group",
                { right: "23%" },
                { top: "15%" },
                "外向 不稳定"
            ),
            this.returnText(
                "group",
                { right: "center" },
                { bottom: "2px" },
                "内外向"
            ),
            this.returnText(
                "group",
                { left: "10%" },
                { bottom: "center" },
                ["神", "经", "质"].join("\n")
            ),
          ],
          series: [
            {
              type: "scatter",
              encode: { tooltip: [0, 1] },
              symbolSize: 15,
              itemStyle: {
                borderColor: "#555",
              },
              datasetIndex: 1,
            },
            {
              type: "scatter",
              encode: { tooltip: [0, 1] },
              symbolSize: 0,
              data: [50, 50],
              itemStyle: {
                borderColor: "#fff",
                backgroundColor: "#fff",
              },
              datasetIndex: 1,
              markArea: {
                itemStyle: {
                  normal: {
                    color: "",
                    type: "solid",
                  },
                },
                data: [
                  [
                    {
                      yAxis: 56.7,
                    },
                    {
                      yAxis: 61.5,
                    },
                  ],
                  [
                    {
                      xAxis: 43.3,
                    },
                    {
                      xAxis: 56.7,
                    },
                  ],
                ],
              },
              markLine: {
                label: {
                  show: false,
                },
                lineStyle: {
                  normal: {
                    color: "#666",
                    type: "solid",
                  },
                },
                symbol: "none",
                data: [
                  {
                    name: "X 轴值为 100 的竖直线",
                    xAxis: (56.7 + 43.3) / 2,
                  },
                  {
                    name: "y 轴值为 100 的竖直线",
                    yAxis: (56.7 + 61.5) / 2,
                  },
                ],
              },
            },
          ],
        });
      } catch {
        console.log("数据错误");
      }
    },
    lineEchartFun(reportcontext) {
      try {
        this.lineEChart = echarts.init(document.getElementById("lineEChart"));
        let lineList =
            reportcontext &&
            reportcontext.filter((e) => e.typecode == "015")[0].quotaList;
        let rowList = lineList.map((e) => e.quotaname);
        let colList = lineList.map((e) => e.quotascore || 0);
        this.lineEChart.setOption({
          lengend: {},
          grid: {
            top: "10%",
            left: "10%",
            right: "10%",
            bottom: "10%",
          },
          tooltip: {
            trigger: "item",
            confine: true,
            position: ["0%", "50%"],
          },
          yAxis: {
            type: "category",
            data: rowList,
            lineStyle: {
              color: "black",
            },
            axisLabel: {
              color: "rgba(95,112,132,1)",
              fontSize: "8",
              interval: 0,
              formatter: function (val) {
                // var strs = val.split(''); //字符串数组
                // var str = ''
                // for (var i = 0, s; s = strs[i++];) { //遍历字符串数组
                // 	str += s;
                // 	if (!(i % 7)) str += '\n'; //按需要求余
                // }
                return val;
              },
            },
          },

          xAxis: {
            type: "value",
          },

          series: [
            {
              data: colList,
              type: "line",
              lineStyle: {
                color: "#666",
              },
            },
          ],
        });
      } catch (err) {
        console.log("数据错误");
      }
    },

    initEchartsFun(list) {
      try {
        this.myChart = echarts.init(document.getElementById("echarts"));
        let reportcontext = list.filter(
            (e) => e.typecode != "005" && e.typecode != "012"
        );
        const indicator = reportcontext.map((e) => {
          return {text: e.quotaname || "", max: 100};
        });
        let progress = reportcontext.map(
            (e) => Number(Number(e.quotascore) / Number(e.quotatotalpoints)) * 100
        );
        let progress2 = reportcontext.map(
            (e) =>
                Number(Number(e.quotatemplate) / Number(e.quotatotalpoints)) * 100
        );
        this.myChart = echarts.init(document.getElementById("echarts"));
        //
        this.myChart.setOption({
          title: {
            text: "",
          },
          // tooltip: {
          //   trigger: "item",
          //   confine: true,
          //   position: ["0%", "50%"],

          // },
          color: ["#32cd32", "#ff84a6"], //2个圆点的颜色
          legend: {
            itemHeight: 6, //图例的高度
            itemGap: 20, //图例间距
            itemWidth: 10, //图例的宽度
            top: 0,
            textStyle: {
              //整体设置文字部分的样式
              color: "#666",
              fontSize: 16,
              fontweight: 400,
              height: 30,
              lineHeight: 30,
              margin: 30,
            },
            data: ["测评结果", "常模值"],
          },
          radar: [
            {
              indicator,
              center: ["50%", "55%"],
              radius: 100,
              // startAngle: 90,
              splitNumber: 5,
              shape: "circle",
              name: {
                formatter: (val) => {
                  var strs = val.split(""); //字符串数组
                  var str = "";
                  for (var i = 0, s; (s = strs[i++]); ) {
                    //遍历字符串数组
                    str += s;
                    if (!(i % 6)) str += "\n"; //按需要求余
                  }
                  return str;
                },
                textStyle: {
                  color: "#333",
                  fontSize:14
                },
              },
              splitArea: {
                areaStyle: {
                  color: "white",
                },
              },
              axisLine: {
                lineStyle: {
                  color: "#999",
                },
              },
              splitLine: {
                lineStyle: {
                  color: "#ccc",
                },
              },
            },
          ],
          series: [
            {
              name: "雷达图",
              type: "radar",
              emphasis: {
                lineStyle: {
                  width: 4,
                },
              },
              data: [
                {
                  value: progress2,
                  name: "常模值",
                  lineStyle: {
                    type: "dashed",
                    color: "#32cd32", //适用于线值颜色
                  },
                  areaStyle: {
                    color: "#32cd32", //适用于背景值颜色
                  },
                },
                {
                  value: progress,
                  name: "测评结果",
                  symbol: "rect",
                  lineStyle: {
                    color: "#ff84a6", //适用于线值颜色
                  },
                  areaStyle: {
                    color: "#ff84a6", //适用于背景值颜色
                  },
                },
              ],
            },
          ],
        });
      } catch {
        console.log("initEchartsFun数据错误");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts-page-web {
  [v-cloak] {
    display: none;
  }
  div {
    box-sizing: border-box;
  }
  .pc {
    width: 1200px;
    margin: 0 auto;
    background: white;
    height: 100%;
    padding: 15px;
  }

  .pc-box {
    border: 2px solid black;
    height: 100%;
  }

  .item-wrap-title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: black;
    height: 30px;
  }

  .line {
    width: 3px;
    height: 13px;
    background: #319cfc;
    opacity: 1;
    border-radius: 3px;
    margin-left: 15px;
    margin-right: 10px;
  }

  .item-wrap-content {
    padding: 0;
  }

  .item-wrap {
    background-color: #fff;
    position: relative;
  }

  .time {
    display: flex;
    align-items: center;

  }
  .item-wrap-flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 2px solid black;
    //margin-bottom: 5px;
  }

  .item-wrap-cell {
    width: 50%;
    color: black;
    font-size: 16px;
    padding: 5px 15px;
    display: flex;
  }

  .pc-cell {
    color: black;
    font-size: 16px;
    padding: 5px 15px;
    display: flex;
    width: 100%;
    border-bottom: 2px solid black;
  }
  .pc-result-cell {
    color: black;
    font-size: 16px;
    padding: 5px 15px;
  }
  .item-wrap-cell .label {
    display: inline-block;
    width: 150px;
    color: black;
  }

  .item-wrap-cell .value {
    color: black;
    display: inline-block;
    flex: 1;
    width: 0px;
  }

  .bbn {
    border-bottom: 0;
  }

  .echarts {
    height: 360px;
    width: 500px;
  }
  .echarts .canvas {
    width: 50%;
  }

  .pc-detail {
    color: #666;
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;
  }

  .pc-detail-text {
    padding: 5px 15px;
    color: black;
  }
  .pc-detail-result {
    padding: 5px 15px;
    color: black;
    border-bottom: 2px solid #f6f6f6;
  }
  .pc-detail-flex {
  }
  .reportItemTitle {
    /* display: flex; */
  }
  .compareEcharts {
    height: 300px;
  }
  .scatterEChart {
    height: 200px;
  }
  .scl90_container {
    margin-top: 25px;
  }
  .lineEChart {
    height: 200px;
  }
  .report-tabel {
    padding: 15px ;
    border-bottom: 2px solid black;
    font-size: 14px;
    display: flex;
  }

  .pc-detail-padding {
    padding: 5px 15px;
    //float: right;
    width: 650px;
    height: 40%;
  }
  .pc-detail-padding-left{
    width: 650px;
    height: 60%;
  }

  .print {
    margin: 10px 0;
  }
  .border-bottom-0 {
    border-bottom: 0;
  }
  .echarts-detail{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    left: 600px;
    border-bottom: 2px solid black;
  }
}
@media print {
  .no-print {
    display: none;
  }
}
@page {
  size: auto;
  margin: 0mm;
}
</style>
